import { useNavigate } from 'react-router-dom'
import { loadFacilities, loadOldShipmentName } from '../utils'
import { GoChevronLeft } from 'react-icons/go'
import ShipmentParty from '../Modules/ShipmentParty'
import LoadBasicInfo from '../Modules/LoadBasicInfo'
import CarrierTruck from '../Modules/CarrierTruck'
import LoadLogistics from '../Modules/LoadLogistics'
import ShipmentName from '../Modules/ShipmentName'
import FButton from '../../../components/FButton'
import FIconWrapper from '../../../components/FIconWrapper'
import ESpinner from '../../../components/ESpinner'
import MessageCard from '../../../components/MessageCard'
import Modal from '@mui/material/Modal'
import { useState } from 'react'

const LoadForm = ({
  Formik,
  formType,
  loadInfo,
  setUnits,
  setIsExistingShipment,
  setSelectorKeyForPickup,
  setSelectorKeyForDrop,
  formTitle,
  primaryBtnTitle,
  primaryLoading,
  secondaryBtnTitle,
  onClickSecondary,
  secondaryLoading,
  isLoading = false, // on fetching data
  // error = null,
}) => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const scrollToError = () => {
    const element = Formik.errors
    setTimeout(() => {
      if (element) {
        const firstError = Object.keys(element)[0]
        const elementToScroll = document.getElementById(firstError)
        if (elementToScroll) {
          elementToScroll.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      }
    }, 100)
  }

  return (
    <div className={'f-col-center-center'}>
      <div
        className={
          'primary-shadow sticky top-0 block w-full border-b bg-white p-3 ' +
          'z-10 text-left text-xl font-semibold text-gray-700 '
        }
      >
        <FIconWrapper>
          <div
            onClick={() => setIsModalOpen(true)}
            className={'cursor-pointer rounded p-0.5 hover:bg-gray-100'}
          >
            <GoChevronLeft size={25} />
          </div>
          <span>{formTitle}</span>
        </FIconWrapper>

        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div
            className="absolute top-1/2 left-1/2  w-11/12 -translate-x-1/2 -translate-y-1/2 transform bg-white  shadow-sm lg:w-5/12
        "
          >
            <div className="w-Full bg-primary-500 p-2"></div>

            <div className="flex flex-col p-5">
              <div className="flex flex-row">
                <div className=" flex flex-col gap-2">
                  <div className="text-gray-600 font-medium text-lg">
                    {` You are about to leave this page, any unsaved data will be lost,Do you want to continue saving this load as draft ?`}
                  </div>
                </div>
              </div>
              <hr className="mt-5" />

              <div className="mt-5 flex flex-row-reverse  gap-5">
                <FButton onClick={() => {

                    setIsModalOpen(false)
                    onClickSecondary()
                    navigate(-1)

                    
                }} className={''}>
                  Save as Draft
                </FButton>
                <FButton
                  onClick={() => {
                    setIsModalOpen(false)
                    navigate(-1)
                  }}
                  btnType={'secondary'}
                >
                    Leave
                </FButton>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      {isLoading ? (
        <div className={'f-col-center-center mt-60 h-full w-full '}>
          <ESpinner isVisible={true} size={30} />
          <span>Loading...</span>
        </div>
      ) : (
        <form className={'my-5 flex flex-col gap-5'}>
          <div className="w-full">
            {formType !== 'edit' && (
              <MessageCard
                message={
                  'Fields are required just for creating a load not for saving a draft'
                }
                type={'pending'}
              />
            )}
          </div>
          <ShipmentName
            Formik={Formik}
            loadOldShipmentName={loadOldShipmentName}
            isExistingShipment={loadInfo.isExistingShipment}
            setIsExistingShipment={setIsExistingShipment}
          />
          <LoadBasicInfo Formik={Formik} units={loadInfo.units} setUnits={setUnits} />
          <ShipmentParty
            // error={loadInfo.error}
            selectorKeyForDrop={setSelectorKeyForDrop}
            selectorKeyForPickup={setSelectorKeyForPickup}
            Formik={Formik}
            loadInfo={loadInfo}
          />
          <LoadLogistics
            Formik={Formik}
            selectorKeyForDrop={loadInfo.selectorKeyForDrop}
            selectorKeyForPickup={loadInfo.selectorKeyForPickup}
            loadFacilities={loadFacilities}
          />
          <CarrierTruck Formik={Formik} />
          <small className={'text-left font-medium text-red-700'}>{loadInfo.error}</small>
          {/* Add extra fields wrt formType */}
          <div className={'mt-2 flex  justify-between gap-2'}>
            <FButton
              btnType={'secondary'}
              disabled={secondaryLoading || primaryLoading}
              onClick={onClickSecondary}
            >
              <span className="flex gap-2">
                {secondaryBtnTitle}
                <ESpinner isVisible={secondaryLoading} />
              </span>
            </FButton>
            <FButton
              btnType={'primary'}
              disabled={primaryLoading || secondaryLoading}
              onClick={() => {
                Formik.handleSubmit()
                scrollToError()
              }}
            >
              <span className="flex gap-2">
                {primaryBtnTitle} <ESpinner isVisible={primaryLoading} />
              </span>
            </FButton>
          </div>
        </form>
      )}
    </div>
  )
}

export default LoadForm
